@font-face {
    font-family: TcomSans;
    src: url(../assets/fonts/ToyotaType-Regular.woff2) format("woff2");
}
@font-face {
    font-family: TcomSemiBold;
    src: url(../assets/fonts/ToyotaType-Semibold.woff2) format("woff2");
}
@font-face {
    font-family: TcomLight;
    src: url(../assets/fonts/ToyotaType-Light.woff2) format("woff2");
}
@font-face {
    font-family: Nobel;
    src: url(../assets/fonts/Nobel/nobel-regular.woff) format("woff");
}
@font-face {
    font-family: NobelBook;
    src: url(../assets/fonts/Nobel/nobelbook.woff) format("woff");
}
@font-face {
    font-family: NobelBold;
    src: url(../assets/fonts/Nobel/nobelbold.woff) format("woff");
}

@font-face {
    font-family: NobelLight;
    src: url(../assets/fonts/Nobel/nobellight.woff) format("woff");
}

@font-face {
    font-family: ToyotaIcon;
    src: url(../assets/fonts/toyota-icon.ttf) format("truetype");
}

h6, h5, h4, h3, h2, h1 {
    margin-top: 0;
    margin-bottom: 0.5rem;
    font-weight: 500;
    line-height: 1.2;
    color: var(--bs-heading-color);
    font-family:  var(--bs-font-toyota)!important;
}


h1 {
    font-size: calc(1.375rem + 1.5vw);
}

h2 {
    font-size: calc(1.325rem + 0.9vw);
}


h3 {
    font-size: calc(1.3rem + 0.6vw);
}

h4 {
    font-size: calc(1.275rem + 0.3vw);
}
@media (min-width: 1200px) {
    /* h1 {
        font-size: 2.5rem;
    }
    h2 {
        font-size: 2rem;
    }
    h3 {
        font-size: 1.75rem;
    }
    h4 {
        font-size: 1.5rem;
    } */
    h1 {
        font-size: 25px;
    }
    h2 {
        font-size: 20px;
    }
    h3 {
        font-size: 13px;
    }
    h4 {
        font-size: 1.5rem;
    }
}

h5 {
    font-size: 1.25rem;
}

h6 {
    font-size: 1rem;
}

p {
    margin-top: 0;
    margin-bottom: 1rem;
}
small {
    font-size: 0.875em;
} 
.text-center{
    text-align: center;
}
.text-right{
    text-align: right;
}
.text-left{
    text-align: left;
}
.mat-h1, .mat-headline, .mat-typography .mat-h1, .mat-typography .mat-headline, .mat-typography h1 {
    font: 500 calc(1.125rem + .25vw)/28px var(--bs-body-font-family);
}
.mat-h2, .mat-title, .mat-typography .mat-h2, .mat-typography .mat-title, .mat-typography h2 {
    font: 500 16px/24px var(--bs-body-font-family);
    letter-spacing: normal;
    margin: 0 0 14px;
}
@media (min-width:768px) {
    .mat-h1, .mat-headline, .mat-typography .mat-h1, .mat-typography .mat-headline, .mat-typography h1 {
        font: 500 calc(1.375rem + .25vw)/32px var(--bs-body-font-family);
    } 
    .mat-h2, .mat-title, .mat-typography .mat-h2, .mat-typography .mat-title, .mat-typography h2 {
        font: 500 20px/32px var(--bs-body-font-family);
        letter-spacing: normal;
        margin: 0 0 16px;
    }
}
h1.display-4 {
    font: 400 20px/28px  var(--bs-body-font-family);
    letter-spacing: normal;
    margin: 0 ;
}
h1 span {
    letter-spacing: .5px;
}
h2.display-4 {
    font: 400 18px/28px  var(--bs-body-font-family);
    letter-spacing: normal;
    margin: 0 ;
}
@media (max-width:991px){
    h1.display-4 {
        font: 400 16px/24px  var(--bs-body-font-family);
    }
    h2.display-4 {
        font: 400 14px/20px  var(--bs-body-font-family);
        letter-spacing: normal;
        margin: 0 ;
    }
}
h2.small {
    font: 500 20px/32px var(--bs-body-font-family);
    letter-spacing: normal;
    margin: 0 0 16px;
}

.min-top-bar {
    font-family: var(--bs-body-font-family);
    font-size: 11px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: center;
    color: #58595b;
    line-height: 28px;
}
@media (max-width: 991px)  {
    .min-top-bar {
        text-align: left;
    }
}
.bg-white{
    background-color: var(--bs-white)!important;
} 
.card-header {
    font-family: var(--bs-font-toyota-semi);
    font-size: 20px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.4;
    letter-spacing: normal;
    color: var(--bs-black);
}
.card-header small{
    font-family: var(--bs-font-toyota-semi);
    font-size: 17px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.4;
    letter-spacing: normal;
    color: var(--bs-black);
}
.card-subheader {
    font-family: var(--bs-body-font-family);
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    color: var(--bs-black);
}
.card-price {
    font-family: var(--bs-font-toyota-light);
    font-size: 24px;
    font-weight: 100;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: -0.5px;
    color: var(--bs-black);
}

.lexus-body h6, .lexus-body h5, .lexus-body h4, 
.lexus-body h3, .lexus-body h2, .lexus-body h1,
.lexus-body .min-top-bar,
.lexus-body h2.small,
.lexus-body h1.display-4,
.lexus-body h2.display-4,
.lexus-body p
{
    font-family:  var(--bs-font-lexus)!important;
}

.lexus-body .card-header {
    font-family: var(--bs-font-lexus-book);
    font-size: 13px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.4;
    letter-spacing: normal;
    color: var(--bs-black);
}
.lexus-body .card-header small{
    font-family: var(--bs-font-lexus-bold);
    font-size: 17px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.4;
    letter-spacing: normal;
    color: var(--bs-black);
}
.lexus-body .card-subheader {
    font-family: var(--bs-font-lexus-book);
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 23px;
    letter-spacing: 0.1em;
    color: var(--bs-black);
    text-transform: uppercase;
}
.lexus-body .card-price {
    font-family: var(--bs-font-lexus-book);
    font-size: 22px;
    font-weight: 100;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: 0.1em;
    color: var(--bs-black);
}
.lexus-body .card-color-name {
    font-family: var(--bs-font-lexus);
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 20px;
    color: var(--bs-black);
    text-transform: uppercase;
}
.lexus-body .card-car-tittle {
    font-family: var(--bs-font-lexus-bold);
    font-stretch: normal;
    font-style: normal;
    color: var(--bs-black);
    letter-spacing: 0.04em;
    font-size: 16px;
    line-height: 24px;
    text-transform: uppercase;
}

@media (min-width: 1700px)  {
    .lexus-body .card-car-tittle {
       font-size: 20px;
        line-height: 30px;
    }
    .lexus-body .card-subheader {
        font-size:15px;
        line-height: 28px;
    }
}

.toyota-btn-primary {
    height: 56px;
    margin: 0;
    padding: 0 16px;
    border-radius: 2px;
    background-color: var(--bs-btn-bg);
    font-family: var(--bs-body-font-family);
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.71;
    letter-spacing: 0.5px;
    color: #fff;
    border: none;
    cursor: pointer;
}

.toyota-btn-primary:hover, 
.toyota-btn-primary:focus{
    background-color: var(--bs-btn-bg-dark); 
    transform: scale(1.02, 1.02);
    -webkit-transform: scale(1.02, 1.02);
    -moz-transform: scale(1.02, 1.02);
}
.toyota-btn-secondary {
    height: 56px;
    margin: 0;
    padding: 0 16px;
    border-radius: 2px;
    background-color: transparent;
    font-family: var(--bs-body-font-family);
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.71;
    letter-spacing: 0.5px;
    color: #000;
    cursor: pointer;
    border: solid 1px #767676;
}
.toyota-btn-secondary:hover, 
.toyota-btn-secondary:focus{
    border: solid 2px #767676;
    transform:scale(1.02, 1.02);
    -webkit-transform:scale(1.02, 1.02);
    -moz-transform:scale(1.02, 1.02);
}

.toyota-btn-sm {
    height: 40px;
    font-size: 12px;
}
@media(max-width:991px){
    .toyota-btn-primary, .toyota-btn-secondary  {
        height: 40px;
        font-size: 12px;
    } 
}


.lexus-btn-primary {
    background-color: var(--bs-white);
    color: black;
    align-content: right;
    font-size: 12px;
    text-align: center;
    font-weight:bolder;
    /* height: 23px; */
    border: solid 2px black;
    padding: 8px 24px;
    cursor: pointer;
    text-transform: uppercase;
    font-style: normal;
    /* font-family: Impact, Haettenschweiler, sans-serif; */
    font-stretch: expanded;
     letter-spacing: 0.1rem;
     margin-top:2%;
     width:150px
  }
  @media(max-width:1280px){
    
    .lexus-btn-primary.lexus-sm{
      font-size: 13px;
      padding: 13px 1px;
      font-weight: 400;
      letter-spacing: 0.1rem;
      width:120px
    }
  }
  @media(max-width:576px){
    .lexus-btn-primary.lexus-lg {
      width: 100%;
    }
    .lexus-btn-primary.lexus-sm{
      font-size: 8px;
      padding: 8px 1px;
      border:solid 1.5px black;
      width:80px
    }
  }
  
  
  
  .lexus-btn-primary:hover {
    background-color: black;
    color: var(--bs-white);
  }
